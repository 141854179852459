<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-form-group label="Ключ">
      <b-form-input v-model="$v.propertyData.key.$model" required="required"
                    :readonly="!isAdmin || !creating"
                    :state="$v.propertyData.key.$dirty ? !$v.propertyData.key.$error : null">
      </b-form-input>
    </b-form-group>
    <b-form-group label="Значение">
      <b-form-input v-model="$v.propertyData.value.$model" required="required"
                    :readonly="!isAdmin"
                    :state="$v.propertyData.value.$dirty ? !$v.propertyData.value.$error : null">
      </b-form-input>
    </b-form-group>
    <b-button v-if="isAdmin" variant="primary" class="w-100 mt-3" type="submit" :disabled="submitting">
      Сохранить
    </b-button>
    <b-button class="w-100 mt-4" v-bind:to="'/properties'">
      Назад
    </b-button>
  </b-form>
</template>
<script>
import paramsApi from '@/modules/server-params';
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  props: {
    creating: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    if (!this.creating) {
      this.fetchProperty(this.$route.params.id);
    }
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      submitting: false,
      propertyData: {
        key: null,
        value: null
      },
    };
  },
  validations() {
    return {
      propertyData: {
        key: {
          required
        },
        value: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    onSubmit() {
      this.$v.propertyData.$touch();
      if (this.submitting || this.$v.propertyData.$anyError) {
        return;
      }

      this.submitting = true;
      this.saveProperty()
          .finally(() => {
            this.submitting = false;
          })
    },
    fetchProperty(key) {
      paramsApi.getParam(key)
          .then(response => {
            this.propertyData = response.data;
          })
          .catch(error => {
            this.handleError(error);
          });
    },
    saveProperty() {
      return (this.creating ? paramsApi.createParam(this.propertyData) : paramsApi.createOrUpdateParam(this.propertyData))
          .then(() => {
            this.$router.push('/properties');
          })
          .catch(error => {
            this.handleError(error);
          });
    },
    handleError(error) {
      let errorMessage;
      if (error.response != null) {
        if (error.response.status === 409) {
          errorMessage = `Параметр с ключом "${this.propertyData.key}" уже существует`;
        } else {
          errorMessage = `Ошибка при сохранении параметра (код ${error.response.status})`;
        }
      } else {
        errorMessage = 'Неизвестная ошибка при сохранении параметра';
      }
      this.$bvToast.toast(errorMessage, {
        title: 'Ошибка',
        variant: 'danger',
        autoHideDelay: 5000,
        appendToast: true
      });
    }
  }
}
</script>
