import {AXIOS} from './backend-config';

export default {
    getParamKeyList(pageNumber, pageSize) {
        return AXIOS.get('/admin/keys', {
            params: {
                pageNumber,
                pageSize
            }
        });
    },
    updateParamKey(keyData) {
        return AXIOS.put('/admin/keys', JSON.stringify(keyData), {
            headers: {
                "Content-Type": "application/json"
            }
        })
    }
}