import {AXIOS} from './backend-config';

export default {
    getParamsList(pageNumber, pageSize) {
        return AXIOS.get('/admin/app_properties', {
            params: {
                pageNumber,
                pageSize
            }
        });
    },
    getParam(key) {
      return AXIOS.get(`/admin/app_properties/${key}`);
    },
    createOrUpdateParam(data) {
        return AXIOS.put('/admin/app_properties',
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    },
    createParam(data) {
        return AXIOS.post('/admin/app_properties',
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    },
    deleteParam(key) {
        return AXIOS.delete(`/admin/app_properties/${key}`);
    },
    getBackendVersion() {
        return AXIOS.get('application-info/versions')
    }
}
