<template>
  <div>
    <b-form-group label="Идентификатор пользователя">
      <b-form-input v-model="userId" readonly/>
    </b-form-group>
    <b-form-group label="Параметры пользователя">
      <b-table
          hover
          :items="getUserDataTable"
          :fields="fields"
          :current-page="propertyList.currentPage"
          :per-page="propertyList.perPage"
          class="table-bordered table-hover"
          show-empty>
        <template v-slot:empty="scope">
          <h6 class="text-center">Параметры отсутствуют</h6>
        </template>
      </b-table>
      <div class="d-flex">
        <div class="col-md-2 pl-0">
          <b-form-select
              v-model="propertyList.perPage"
              class="text-light border-secondary bg-secondary">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </b-form-select>
        </div>
        <span class="align-self-center">записей на страницу</span>
        <b-pagination
            v-model="propertyList.currentPage"
            class="my-0 ml-auto"
            :total-rows="propertyList.totalCount"
            :per-page="propertyList.perPage"
            aria-controls="template-table"
            @input="getUserDataTable">
        </b-pagination>
      </div>
    </b-form-group>
  </div>
</template>
<script>
import userApi from '@/modules/user-params';

export default {
  name: 'user-list',
  data() {
    return {
      userId: this.$route.params.id,
      fields: [
        {
          key: 'key', label: 'Ключ'
        },
        {
          key: 'value', label: 'Значение'
        }
      ],
      propertyList: {
        perPage: 20,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  mounted() {
    document.title = this.$route.meta.title;
  },
  methods: {
    getUserDataTable(context) {
      return userApi.getUserProperties(this.userId, context.currentPage, context.perPage)
          .then(resp => {
            this.propertyList.totalCount = resp.data.totalCount;
            return resp.data.items;
          }).catch((error) => {
            let errorMessage;
            if (error.isAxiosError) {
              errorMessage = `Ошибка при запросе пользователя (код ${error.response?.status})`;
            } else {
              errorMessage = 'Неизвестная ошибка при запросе данных пользователя';
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });

            throw error;
          });
    }
  }
}
</script>
