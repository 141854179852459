import {AXIOS} from './backend-config';

export default {
    getUsers(pageNumber, pageSize) {
      return AXIOS.get('/admin/service_users', {
          params: {
              pageNumber,
              pageSize
          }
      })
    },
    getUserProperties(user, pageNumber, pageSize) {
        return AXIOS.get('/admin/user_properties', {
            params: {
                user,
                pageNumber,
                pageSize
            }
        });
    }
}
