<template>
  <div>
    <b-table
        hover
        :items="getPropertyKeys"
        :fields="fields"
        :current-page="keyList.currentPage"
        :per-page="keyList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Ключи отсутствуют</h6>
      </template>
      <template v-if="isAdmin" v-slot:cell(defaultValue)="row">
        <div class="d-flex">
          <b-form-input v-model="row.item.defaultValue" />
          <div class="pt-1 pl-2">
            <b-button size="sm" variant="success"
                      title="Сохранить"
                      @click="savePropertyKey(row.item)">
              <b-icon-check />
            </b-button>
          </div>
        </div>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="keyList.perPage"
            class="text-light border-secondary bg-secondary">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="keyList.currentPage"
          class="my-0 ml-auto"
          :total-rows="keyList.totalCount"
          :per-page="keyList.perPage"
          aria-controls="template-table"
          @input="getPropertyKeys">
      </b-pagination>
    </div>
  </div>
</template>
<script>
import keysApi from '@/modules/param-keys';
import {mapGetters} from "vuex";

export default {
  name: 'property-key-list',
  data() {
    return {
      fields: [
        {
          key: 'key', label: 'Ключ'
        },
        {
          key: 'defaultValue', label: 'Значение по умолчанию'
        }
      ],
      keyList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  mounted() {
    document.title = this.$route.meta.title;
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    getPropertyKeys(context) {
      return keysApi.getParamKeyList(context.currentPage, context.perPage)
          .then(resp => {
            this.keyList.totalCount = resp.data.totalCount;
            return resp.data.items;
          }).catch((error) => {
            let errorMessage;
            if (error.isAxiosError) {
              errorMessage = `Ошибка при запросе ключей параметров (код ${error.response?.status})`;
            } else {
              errorMessage = 'Неизвестная ошибка при запросе ключей для параметров';
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });

            throw error;
          });
    },
    savePropertyKey(keyData) {
      return keysApi.updateParamKey(keyData)
          .then(() => {
            this.$bvToast.toast('Значение успешно обновлено', {
              variant: 'success',
              solid: true,
              noCloseButton: true
            });
          })
          .catch(error => {
            let errorMessage;
            if (error.response == null) {
              errorMessage = 'Ошибка обновления значения';
            } else {
              if (error.response.status === 403) {
                errorMessage = 'Недостаточно прав для изменения значения';
              } else if (error.response.status === 401) {
                errorMessage = 'Сессия истекла. Пожалуйста, перезайдите в аккаунт';
              } else {
                errorMessage = `Ошибка обновления значения (код ${error.response.status})`;
              }
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });

            throw error;
          });
    }
  }
}
</script>
